import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/layout/index.jsx";
import { graphql, Link } from 'gatsby';
export const pageQuery = graphql`
  query {
    allMdx(
      filter: {frontmatter: {type: {eq: "subcategory"}}, fileAbsolutePath: {regex: "/transportation-engineering/"}}
    )
    
    {
      edges {
        node {
          frontmatter {
            type
            title
          }
          slug
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Transportation Engineering`}</h1>
    <div>
      {props.data.allMdx.edges.map(x => <Link to={'/' + x.node.slug} key={x.node.frontmatter.title} mdxType="Link">
            <div>
              {x.node.frontmatter.title}
            </div>
          </Link>)}
    </div>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      